import getDefaultParam from './utils/getDefaultParam';
import { organizationIdSelector, configSelector } from './redux/app/selectors';
import http, { defaultResponseHandler } from './utils/redux/http';
import RadLink from './components/common/rad-link';
import { store } from './redux/store';
import router from './router';
import { setStore } from './utils/redux/store';
import radHttp from './utils/rad-http';

setStore(store);

RadLink.router = router;

http.getDefaultParam = getDefaultParam;
radHttp.getDefaultParam = getDefaultParam;

http.defaultResponseHandler = ({
  method, url, options, request, response = null, error = null, data = null, dataType = null
}) => {
  if (response?.status === 401) {
    window.location.href = `${configSelector().loginUrl}?ReturnUrl=${encodeURIComponent(window.location.href)}`;
    return;
  }

  defaultResponseHandler({ method, url, options, request, response, error, data, dataType });
};

http.requestDecorator = ({ headers = {}, ...rest }) => {
  const organizationId = organizationIdSelector();

  organizationId && (headers['x-target-organization-id'] = organizationId);

  return {
    ...rest,
    headers
  };
};

// Must be this style import or else getDefaultParam won't be set in time.
import('./components/rad-app');